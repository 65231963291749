<template>
  <el-card shadow="never" style="height: 100%">
    <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
    >
      <el-form-item label="类别名称" prop="name">
        <el-input v-model="ruleForm.name" autocomplete="off" ></el-input>
      </el-form-item>
      <el-form-item label="父类别名称" prop="parentId">
        <el-select v-model="value" placeholder="请选择">
          <el-option
              v-for="item in bigOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <!-- <el-form-item label="备注" prop="text">
      <el-input type="textarea"
    v-model.number="ruleForm.title ">
  </el-input>
    </el-form-item> -->
      <!-- <el-form-item label="编辑时间" prop="time">
      <el-date-picker
        v-model="value1"
        type="date"
        placeholder="选择日期">
      </el-date-picker>
    </el-form-item> -->

      <el-form-item>
        <el-button type="primary" @click="addTypeMid('ruleForm')" style="background: #1d90ff; border:none" :disabled="this.show">提交</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
export default {
  data() {
    return {
      show:false,
      rules: {
        name: [
          {required: true, message: "请输入类别名称", trigger: "blur"},
        ],
        // parentId: [
        //   {required: true, message: "请选择类别", trigger: "blur"},
        // ],
      },
      ruleForm: {
        name: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      bigOptions: [],
      value: "",
    };
  },
  mounted() {
    this.getBigTypeList();
  },
  methods: {
    addTypeMid(ruleForm) {
      this.show = true;
      // console.log(this.$refs[ruleForm]);
      this.$nextTick(() => {
        this.$refs[ruleForm].validateField("street");
      });
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.$api.post(
          "/type/mid/save",
          null,
          {name: this.ruleForm.name, parentId: this.value},
          (successRes) => {
            console.log(successRes);
            if (successRes.status == 200) {
              this.$message('操作成功');
              
              this.$router.push({name: "mmasterList"});
            } else {
              this.$message(successRes.message);
            }
          },
          (failureRes) => {
            this.$message('操作失败');
          }
      );
          
        } else {
          console.log("error submit!!");
          this.$message("输入数据不正确！");
          return false;
        }
      });
    },
    getBigTypeList() {
      this.$api.get(
          "/type/big/list",
          null,
          {
            list:false
          },
          (successRes) => {
            console.log(successRes);
            this.bigOptions = successRes.data.rows;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
  },
};
</script>